<template>
  <button type="button" class="btn btn-primary custom" @click="handleClick(module, target)">Create</button>
</template>

<script>
export default {
  props: ['module', 'target'],
  data() {
    return {}
  },
  methods: {
    handleClick (module, target) {
      if (module) {
        this.$router.push(`/${module}/${target}/create`)
      } else if (!module) {
        this.$router.push(`/${target}/create`)
      }
    },
  },
}
</script>

<style lang="css" scoped>
  @media only screen and (min-width: 768px) {
    .custom {
      padding-top: 7px;
      padding-bottom: 7px;
      margin-left: 5px;
      border-radius: 5px;
    }
  }
  @media only screen and (max-width: 768px) {
  .custom {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 3px;
    border-radius: 5px;
  }
  }
</style>
